// src/components/Logo.js
import React from 'react';
import logo from '../assets/images/dice_dash_logo.png';
import './Logo.css';

const Logo = () => {
    return (
        <div className="logo">
            <img src={logo} alt="Dice Dash Logo" />
        </div>
    );
};

export default Logo;
