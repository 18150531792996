// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Parallax from './components/Parallax';
import Logo from './components/Logo';
import Container from './components/Container';

// Import images for reuse
import parallaxImage1 from './assets/images/DD_prototype2_004.jpeg';
import parallaxImage2 from './assets/images/DD_prototype2_004.jpeg';

function App() {
    return (
        <Router>
            <div className="App">
                <Routes>
                    {/* Main route with signup form */}
                    <Route
                        path="/"
                        element={
                            <>
                                <Parallax image={parallaxImage1} height="30vh" />
                                <Logo />
                                <Container />
                                <Parallax image={parallaxImage2} height="50vh" />
                            </>
                        }
                    />
                </Routes>
            </div>
        </Router>
    );
}

export default App;
