// src/components/Parallax.js
import React from 'react';
import './Parallax.css';

const Parallax = ({ image, height }) => {
    return (
        <div
            className="parallax"
            style={{
                backgroundImage: `url(${image})`,
                height: height,
            }}
        ></div>
    );
};

export default Parallax;
