// src/components/SignupForm.js
import React, { useState } from 'react';
import './SignupForm.css';

const SignupForm = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false); // Add loading state

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true); // Set loading to true when request starts
        setMessage(''); // Clear any existing message

        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/subscribe`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email }),
            });

            if (response.ok) {
                const data = await response.json();
                setMessage(data.message); // Show success message
                setEmail(''); // Clear email field on success
            } else {
                const errorText = await response.text();
                console.error('Error response text:', errorText);
                setMessage('An error occurred. Please try again.');
            }
        } catch (error) {
            console.error('Error:', error);
            setMessage('An unknown error occurred. Please try again.');
        } finally {
            setLoading(false); // Reset loading state when response is received
        }
    };

    return (
        <form onSubmit={handleSubmit} className="signup-form">
            <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email address"
                required
                disabled={loading} // Disable input while loading
            />
            <input
                type="submit"
                value={loading ? "Submitting…" : "Notify Me"} // Show "Submitting…" while loading
                disabled={loading} // Disable button while loading
            />
            {message && <p>{message}</p>}
        </form>
    );
};

export default SignupForm;
