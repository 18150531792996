// src/components/Container.js
import React from 'react';
import SignupForm from './SignupForm';
import './Container.css';

const Container = () => {
    return (
        <div className="container">
            <h1>DICE DASH</h1>
            <p>A fast-paced, fun, and strategic board game coming soon to Kickstarter!</p>
            <p>Sign up below to be notified when we launch.</p>

            {/* Signup Form Component */}
            <SignupForm />

            {/* Footer */}
            <footer>
                <div className="social-links">
                    <p>Follow us for updates:</p>
                    <a href="https://twitter.com/dicedashgame">Twitter</a> |
                    <a href="https://instagram.com/dicedashgame">Instagram</a> |
                    <a href="https://facebook.com/dicedashgame">Facebook</a>
                </div>
            </footer>
        </div>
    );
};

export default Container;
